import { Box, BoxProps, Link } from "@mui/material";
import CoinGecko from "public/static/images/CoinGecko.svg";
import CoinMarketCap from "public/static/images/CoinMarketCap.svg";
import Discord from "public/static/images/Discord.svg";
import Instagram from "public/static/images/Instagram.svg";
import Medium from "public/static/images/Medium.svg";
import Telegram from "public/static/images/Telegram.svg";
import Twitter from "public/static/images/Twitter.svg";
import YouTube from "public/static/images/Youtube.svg";
import { ComponentType, FC } from "react";

type SocialNetwork = {
  href: string;
  label: string;
  icon: ComponentType<JSX.IntrinsicElements["svg"]>;
};

const socialNetworks: SocialNetwork[] = [
  {
    href: "https://t.me/prom_io",
    label: "Telegram",
    icon: Telegram,
  },
  {
    href: "https://twitter.com/prom_io",
    label: "Twitter",
    icon: Twitter,
  },
  {
    href: "https://discord.gg/prom",
    label: "Discord",
    icon: Discord,
  },
  {
    href: "http://instagram.com/prom_io_official",
    label: "Instagram",
    icon: Instagram,
  },
  {
    href: "https://coinmarketcap.com/community/profile/Prom",
    label: "CoinMarketCap",
    icon: CoinMarketCap,
  },
  { href: "https://medium.com/@prom-io", label: "Medium", icon: Medium },
  {
    href: "https://www.youtube.com/channel/UC2mC_ZMPnrivHGgz5CaZnvQ",
    label: "YouTube",
    icon: YouTube,
  },
  {
    href: "https://www.coingecko.com/en/coins/prom",
    label: "CoinGecko",
    icon: CoinGecko,
  },
];

export const SocialNetworks: FC<BoxProps> = ({ ...props }) => {
  return (
    <Box display="grid" {...props}>
      {socialNetworks.map(({ href, label, icon: Icon }) => (
        <Link
          key={href}
          href={href}
          aria-label={label}
          rel="noopener noreferrer"
          target="_blank"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            "&:hover": {
              color: "highlight.main",
            },
          }}
          bgcolor={props.color}
        >
          <Icon width={24} height={24} />
        </Link>
      ))}
    </Box>
  );
};
