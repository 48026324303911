"use client";

import { KeyboardArrowUp, Menu as MenuIcon } from "@mui/icons-material";
import {
  Button,
  Collapse,
  MenuItem,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import { SocialNetworks } from "modules/shared/components/social-networks/SocialNetworks";
import Link from "next/link";
import { usePathname } from "next/navigation";
import {
  FC,
  HTMLAttributeAnchorTarget,
  MouseEvent,
  useEffect,
  useState,
} from "react";
import { AccountButton } from "widgets/account-button";
import { StyledMuiMenu } from "../header/styled-mui-menu/StyledMuiMenu";
import { MobileNestedElement } from "./MobileNestedElement";

export type MenuLink = {
  href: string;
  label: string;
  isExact?: boolean | ((path: string) => boolean);
  target?: HTMLAttributeAnchorTarget;
  children?: {
    href: string;
    label: string;
    target?: HTMLAttributeAnchorTarget;
  }[];
};

type MobileMenuProps = {
  links: MenuLink[];
};

const CollapsableMenuItem: FC<{ link: MenuLink }> = ({ link }) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <MenuItem
        onClick={() => {
          setOpen(!isOpen);
        }}
        sx={{
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: "row",
        }}
      >
        <Typography variant="body1" sx={{ position: "relative" }}>
          {link.label}
        </Typography>
        <KeyboardArrowUp
          sx={{ transform: isOpen ? "rotate(-90deg)" : "rotate(90deg)" }}
        />
      </MenuItem>
      <Collapse in={isOpen}>
        {link.children?.map((childLink) => (
          <Link
            href={childLink.href}
            key={childLink.href}
            passHref
            legacyBehavior
          >
            <MenuItem sx={{ justifyContent: "center" }}>
              {childLink.label}
            </MenuItem>
          </Link>
        ))}
      </Collapse>
    </>
  );
};

export const MobileMenu: FC<MobileMenuProps> = ({ links }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    if (anchorEl) {
      handleClose();
      return;
    }

    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const pathname = usePathname();

  useEffect(() => {
    handleClose();
  }, [pathname]);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <>
      <Button onClick={handleClick} sx={{ color: "currentcolor" }}>
        <MenuIcon />
      </Button>
      <StyledMuiMenu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        zIndex={1099}
        PaperProps={{
          sx: {
            top: "80px!important",
            left: "0!important",
            width: "100%",
            maxWidth: "100%!important",
            borderRadius: 0,
          },
        }}
      >
        {links.map((link) =>
          link.children ? (
            isDesktop ? (
              <CollapsableMenuItem link={link} key={link.label} />
            ) : (
              <MobileNestedElement link={link} key={link.label} />
            )
          ) : (
            <Link href={link.href} passHref key={link.label} legacyBehavior>
              <MenuItem sx={{ alignItems: "center", justifyContent: "start" }}>
                <Typography variant="body1">{link.label}</Typography>
              </MenuItem>
            </Link>
          )
        )}

        <Box sx={{ px: 2, mt: 4 }}>
          <AccountButton />
        </Box>

        <Stack flexDirection="row" justifyContent="space-between" mt={4}>
          <Box display="flex" justifyContent="center" width="100%">
            <SocialNetworks
              gridTemplateColumns="repeat(4, max-content)"
              rowGap={8}
              columnGap={8}
            />
          </Box>
        </Stack>
      </StyledMuiMenu>
    </>
  );
};
