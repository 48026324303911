"use client";

import { Button, Stack } from "@mui/material";
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { Address } from "viem";

export const shortenAddress = (address: Address | string, chars = 4) => {
  return `${address.slice(0, chars + 2)}...${address.slice(-chars)}`;
};

export const AccountButton = () => (
  <ConnectButton.Custom>
    {({
      account,
      chain,
      openAccountModal,
      openChainModal,
      openConnectModal,
      authenticationStatus,
      mounted,
    }) => {
      const ready = mounted && authenticationStatus !== "loading";
      const connected =
        ready &&
        account &&
        chain &&
        (!authenticationStatus || authenticationStatus === "authenticated");

      return (
        <div
          {...(!ready && {
            "aria-hidden": true,
            style: {
              opacity: 0,
              pointerEvents: "none",
              userSelect: "none",
            },
          })}
        >
          {(() => {
            if (!connected) {
              return (
                <Button
                  fullWidth
                  sx={{ height: "50px" }}
                  onClick={openConnectModal}
                  variant="contained"
                >
                  Connect wallet
                </Button>
              );
            }

            if (chain.unsupported) {
              return (
                <Button onClick={openChainModal} variant="contained">
                  Wrong Network
                </Button>
              );
            }

            return (
              <Stack sx={{ gap: 4 }}>
                <Stack sx={{ gap: 4, alignItems: "center" }}>
                  <div>
                    <Button
                      variant="contained"
                      aria-label="account menu"
                      onClick={() => {
                        openAccountModal();
                      }}
                    >
                      {shortenAddress(account.address as Address)}
                    </Button>
                  </div>
                </Stack>
              </Stack>
            );
          })()}
        </div>
      );
    }}
  </ConnectButton.Custom>
);
