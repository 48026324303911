import { FC, SVGProps } from "react";
import LogoSvg from "./assets/logo.svg";
import NextLink from "next/link";

type Props = {
  isLink?: boolean;
} & SVGProps<SVGElement>;

export const Logo: FC<Props> = ({ isLink = true, height = 56, ...props }) => {
  if (isLink) {
    return (
      <NextLink
        aria-label="Prom"
        style={{ display: "inline-flex", width: "122px" }}
        href="/"
        passHref
      >
        <LogoSvg {...props} height={height} />
      </NextLink>
    );
  }

  return <LogoSvg {...props} />;
};
