"use client";

import { KeyboardArrowUp } from "@mui/icons-material";
import { Button, Divider, MenuItem, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Link from "next/link";
import { FC, MouseEvent, useState } from "react";
import { StyledMuiMenu } from "../header/styled-mui-menu/StyledMuiMenu";
import { MenuLink } from "./MobileMenu";

export const MobileNestedElement: FC<{ link: MenuLink }> = ({ link }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    if (anchorEl) {
      handleClose();
      return;
    }

    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <MenuItem
        component="button"
        onClick={handleClick}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          color: "currentcolor",
          width: "100%",
          alignItems: "center",
        }}
        disableRipple
      >
        <Typography variant="body1">{link.label}</Typography>
        <KeyboardArrowUp sx={{ transform: "rotate(90deg)" }} />
      </MenuItem>
      <StyledMuiMenu
        open={open}
        PaperProps={{ sx: { padding: 0 } }}
        anchorEl={anchorEl}
        onClose={handleClose}
        zIndex={1200}
      >
        <Box>
          <Button
            sx={{
              display: "flex",
              direction: "row",
              gap: 4,
              alignItems: "center",
              mb: 6,
              pt: 4,
              px: 6,
            }}
            disableRipple
            onClick={handleClick}
          >
            <KeyboardArrowUp sx={{ transform: "rotate(-90deg)" }} />
            <Typography variant="highlight">{link.label}</Typography>
          </Button>

          <Divider sx={{ mb: 6 }} />

          {link.children?.map((childLink) => (
            <Link
              href={childLink.href}
              key={childLink.href}
              passHref
              legacyBehavior
            >
              <MenuItem sx={{ justifyContent: "start", px: 6 }}>
                {childLink.label}
              </MenuItem>
            </Link>
          ))}
        </Box>
      </StyledMuiMenu>
    </>
  );
};
