"use client";

import { Link, styled } from "@mui/material";

export const AppMenuLink = styled(Link, {
  shouldForwardProp: (prop) => !(prop as string).startsWith("$"),
})<{ $isActive: boolean; $isDashboardStyle?: boolean }>(
  ({ theme, $isActive, $isDashboardStyle }) => ({
    color: "currentColor",
    position: "relative",
    display: "inline-flex",
    alignItems: "center",
    paddingBlock: theme.spacing(2),

    "&:hover": {
      color: theme.palette.highlight.main,
    },

    [theme.breakpoints.up("md")]: {
      ...($isActive &&
        $isDashboardStyle && {
          color: theme.palette.highlight.main,
          ":before": {
            content: '""',
            height: "90%",
            borderLeft: "solid 2px currentColor",
            marginRight: theme.spacing(2),
          },
        }),
    },
  })
);
