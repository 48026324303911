import { Stack, Typography, TypographyVariant } from "@mui/material";
import { FC, useMemo } from "react";
import { CopyButton } from "../copy-button/CopyButton";
import { isAddress } from "ethers/lib/utils"; // or wagmi/utils if needed

export const shortenAddress = (address: string): string => {
  return isAddress(address)
    ? `${address.slice(0, 6)}...${address.slice(-4)}`
    : address;
};

export const Address: FC<{
  shortenAddress?: boolean;
  copy?: boolean;
  address: string;
  variant?: TypographyVariant | "highlight";
}> = ({ shortenAddress: shouldShorten, address, copy, variant = "body1" }) => {
  const displayedAddress = useMemo(() => {
    try {
      return shouldShorten ? shortenAddress(address) : address;
    } catch {
      return address;
    }
  }, [address, shouldShorten]);

  return (
    <Stack display="inline-flex" direction="row" alignItems="center">
      <Typography
        variant={variant}
        component="span"
        sx={{ textOverflow: "ellipsis", overflow: "hidden" }}
      >
        {displayedAddress}
      </Typography>
      {copy && <CopyButton text={address} />}
    </Stack>
  );
};
