"use client";

import { Menu, MenuProps } from "@mui/material";
import { FC } from "react";

export const StyledMuiMenu: FC<{ zIndex: string | number } & MenuProps> = ({
  children,
  zIndex,
  PaperProps,
  ...props
}) => {
  return (
    <Menu
      elevation={0}
      transitionDuration={100}
      sx={{
        zIndex: zIndex,
      }}
      PaperProps={{
        sx: {
          background: (theme) => theme.palette.background.default,
          left: "0!important",
          top: "0px!important",
          width: "100%!important",
          maxWidth: "100%!important",
          height: "100%",
          maxHeight: "100%",
          borderRadius: 0,
          ...PaperProps?.sx,
        },
      }}
      transformOrigin={{ horizontal: "center", vertical: "top" }}
      {...props}
    >
      {children}
    </Menu>
  );
};
