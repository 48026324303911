import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { IconButton, Tooltip, TooltipProps } from "@mui/material";
import copy from "copy-to-clipboard";
import { FC, useCallback, useState } from "react";

export const CopyButton: FC<{
  text: string;
  tooltipTitle?: string;
  onCopyTooltipTitle?: string;
  tooltipPlacement?: TooltipProps["placement"];
}> = ({
  text,
  tooltipTitle = "Copy",
  tooltipPlacement = "top",
  onCopyTooltipTitle = "Copied",
}) => {
  const [isCopying, setIsCopying] = useState(false);

  const handleClick = useCallback(() => {
    copy(text);
    setIsCopying(true);
  }, [text]);

  return (
    <IconButton onClick={handleClick}>
      <Tooltip
        title={isCopying ? onCopyTooltipTitle : tooltipTitle}
        leaveDelay={1000}
        placement={tooltipPlacement}
        onClose={() => setIsCopying(false)}
        arrow
      >
        <ContentCopyOutlinedIcon sx={{ height: "1em" }} />
      </Tooltip>
    </IconButton>
  );
};
