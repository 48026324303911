"use client";

import { gravatar } from "@eezyquote/gradient-avatar";
import CloseIcon from "@mui/icons-material/Close";
import {
  Avatar,
  Button,
  Chip,
  Divider,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { Address } from "modules/shared/components/address/Address";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { ComponentType, FC, MouseEvent, useEffect, useState } from "react";
import { useAccount } from "wagmi";
import { StyledMuiMenu } from "../styled-mui-menu/StyledMuiMenu";
import Favorite from "./assets/Favorite.svg";
import History from "./assets/history.svg";
import LogOut from "./assets/Logout.svg";
import OwnedNFTs from "./assets/ownedNFTs.svg";
import Stats from "./assets/stats.svg";
import TemporaryNFT from "./assets/temporaryNFT.svg";
import TaskSvg from "./assets/task.svg";

type DashboardLink = {
  href?: string;
  label: string;
  icon: ComponentType<JSX.IntrinsicElements["svg"]>;
}[];

const links: DashboardLink = [
  {
    href: "/dashboard/my-nfts",
    label: "Owned NFTs",
    icon: OwnedNFTs,
  },
  {
    label: "Vaults",
    icon: TemporaryNFT,
    href: "/dashboard/vaults",
  },
  {
    href: "/dashboard/history",
    label: "History",
    icon: History,
  },
  {
    label: "Trading Competitions",
    icon: TaskSvg,
    href: "/dashboard/trading-competitions",
  },
  {
    label: "Statistics",
    icon: Stats,
  },
  {
    label: "Favorite NFTs",
    icon: Favorite,
  },
];

const isWalletConnectProvider = (
  provider: any
): provider is { provider: WalletConnectProvider } => {
  return provider?.provider && "wc" in provider.provider;
};

export const MobilePersonalMenu: FC<{
  onlyContent?: boolean;
  onClose?(): void;
}> = ({ onlyContent, onClose }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();

  const handleClose = () => {
    setAnchorEl(null);
    onClose?.();
  };

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    if (onClose) {
      return onClose();
    }

    if (anchorEl) {
      handleClose();
      return;
    }

    setAnchorEl(event.currentTarget);
  };

  const pathname = usePathname();

  useEffect(() => {
    handleClose();
  }, [pathname]);

  const { address: account } = useAccount();
  const avatar = gravatar(account || "", 50);

  const handleDisconnect = async () => {
    try {
      handleClose();
    } catch (error) {
      console.log(error);
    }
  };

  if (!account) {
    return null;
  }

  const content = (
    <>
      <Button
        onClick={handleClick}
        sx={{
          right: 30,
          top: 30,
          position: "absolute",
          minWidth: 0,
          borderRadius: "50%",
        }}
      >
        <CloseIcon />
      </Button>

      <Stack spacing={8}>
        <Stack>
          <Stack direction="row" spacing={4} padding={9}>
            <Avatar sx={{ width: 46, height: 46 }}>
              <span dangerouslySetInnerHTML={{ __html: avatar }}></span>
            </Avatar>

            <Box>
              <Typography variant="subtitle1" sx={{ pb: 2 }}>
                My Account
              </Typography>
              <Chip
                variant="filled"
                label={<Address address={account || ""} shortenAddress copy />}
              ></Chip>
            </Box>
          </Stack>

          <Divider />
        </Stack>

        <Stack px={9} spacing={4}>
          {links.map((link, index) =>
            link.href ? (
              <Link href={link.href} key={index} passHref legacyBehavior>
                <Stack direction="row" spacing={3} alignItems="center">
                  <link.icon
                    width={24}
                    height={24}
                    fill={theme.palette.text.primary}
                  />
                  <Typography variant="body1">{link.label}</Typography>
                </Stack>
              </Link>
            ) : (
              <Stack
                key={index}
                direction="row"
                spacing={3}
                alignItems="center"
              >
                <link.icon
                  width={24}
                  height={24}
                  fill={theme.palette.text.secondary}
                />
                <Typography variant="body1" color="text.secondary">
                  {link.label}
                </Typography>
              </Stack>
            )
          )}

          <Stack
            component="button"
            direction="row"
            spacing={3}
            alignItems="center"
            onClick={handleDisconnect}
          >
            <LogOut width={24} height={24} />
            <Typography variant="body1">Log Out</Typography>
          </Stack>
        </Stack>

        <Divider />
      </Stack>
    </>
  );

  return onlyContent ? (
    content
  ) : (
    <>
      <Button
        onClick={handleClick}
        sx={{ color: "currentcolor", px: 0, minWidth: 0 }}
      >
        <Avatar sx={{ width: 24, height: 24 }}>
          <span dangerouslySetInnerHTML={{ __html: avatar }}></span>
        </Avatar>
      </Button>
      <StyledMuiMenu
        open={open}
        zIndex={1200}
        PaperProps={{ sx: { p: 0 } }}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        {content}
      </StyledMuiMenu>
    </>
  );
};
